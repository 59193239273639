.idle-leave-btn {
  color: #f06548 !important;
  background-color: #fde8e4 !important;
  padding: 5px 20px !important;
  box-shadow: 0px 0px 10px #ccc !important;
  margin: 0px 10px !important;
  text-transform: capitalize !important;
}

.idle-leave-btn:hover {
  color: #fff !important;
  background-color: #f06548 !important;
}

.idle-continue-btn {
  color: #0ab39c !important;
  background-color: #daf4f0 !important;
  margin: 0px 10px !important;
  box-shadow: 0px 0px 10px #ccc !important;

  text-transform: capitalize !important;
}


.idle-continue-btn:hover {
  color: #fff !important;
  background-color: #0ab39c !important;
}

.card-advance {
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  border-radius: 0.375rem;
}