@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik", Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f5f7 !important;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Rubik", Sans-serif;
}

div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
button {
  font-family: "Rubik", Sans-serif !important;
}

.report-count-btn {
  background: none;
  border: none;
}
